import "@arborknot/design-system-v2/dist/bundle.css";
import "@arborknot/design-system-v2/dist/components.css";
import * as FullStory from "@fullstory/browser";
import dayjs from "dayjs";
import customParseFormatPlugin from "dayjs/plugin/customParseFormat";
import dayjsUtcPlugin from "dayjs/plugin/utc";
import "flag-icons/css/flag-icons.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { initI18n } from "./i18n";
import "./index.css";
import { initZendesk } from "./initZendesk";
import reportWebVitals from "./reportWebVitals";
import { ConfigService } from "./services";

const App = React.lazy(() => import("./App"));

function startReact() {
  dayjs.extend(dayjsUtcPlugin);
  dayjs.extend(customParseFormatPlugin);

  if (ConfigService.CONSTANT_TEXT_API_URL) {
    initI18n(ConfigService.CONSTANT_TEXT_API_URL);
  }

  initZendesk();

  if (process.env.REACT_APP_FULL_STORY_ORGANIZATION_ID) {
    FullStory.init({
      orgId: process.env.REACT_APP_FULL_STORY_ORGANIZATION_ID,
    });
  }

  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

  root.render(<App />);

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

startReact();
