class ConfigServiceClass {
  private static instance: ConfigServiceClass;

  private constructor() {}

  public static getInstance(): ConfigServiceClass {
    if (!ConfigServiceClass.instance) {
      ConfigServiceClass.instance = new ConfigServiceClass();
    }
    return ConfigServiceClass.instance;
  }

  public get REACT_APP_ENV() {
    return process.env.REACT_APP_ENV || "DEV";
  }

  public get CONSOLE_API_URL() {
    return this.getEnv("REACT_APP_CONSOLE_API_URL");
  }

  public get CONSTANT_TEXT_API_URL() {
    return this.getEnv("REACT_APP_CONSTANT_TEXT_API_URL");
  }

  public get AUDITY_API_URL() {
    return this.getEnv("REACT_APP_AUDITY_API_URL");
  }

  private getEnv(key: string) {
    const prefix = this.REACT_APP_ENV ? `_${this.REACT_APP_ENV}` : "";
    const value = process.env[`${key}${prefix}`];

    if (!value) {
      console.error(`No env for key ${key}`);
    }

    return value;
  }
}

export const ConfigService = ConfigServiceClass.getInstance();
