import { RootEntities } from "@arborknot/system-enums";
import i18n from "i18next";
import backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

export const initI18n = (constantTextApiUrl: string) => {
  i18n
    .use(backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      fallbackLng: "en-us",
      debug: false,
      backend: {
        allowMultiLoading: false,
        loadPath: (langs: string[], namespaces: string[]) => {
          const dcaId = window.sessionStorage.getItem("dcaId") ?? RootEntities.Dca;
          const [lang] = langs;
          const [namespace] = namespaces;
          return `${constantTextApiUrl}/texts/${lang}/${namespace}?dcaId=${dcaId}`;
        },
      },
      ns: ["console-ui", "ak-error"],
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      load: "currentOnly",
    });
};
